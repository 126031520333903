import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { useInput, customFetch } from "../../custom_hook";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  city_url,
  vehicle_brand_url,
  base_url,
  book_now_url,
} from "../../API/api";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { showToastMessage } from "../../ToastNotification/ToastFunction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  height: 110, // 600
  overflow: "auto",
  gap: 5,
  zIndex: 1200,
  "@media(max-width: 998px)": {
    width: "95%",
    height: "65vh",
    transform: "translate(-50%,-40%)",
  },
};

export default function BasicModal({ open, setOpen }) {
  const [value, setValue] = useState(dayjs("2022-04-07"));
  const [city_drop_menu, setCity_drop_menu] = useState([]);
  const [vehicle_brand_menu, setVehicle_brand_menu] = useState([]);
  const [vehicle_model_menu, setVehicle_model_menu] = useState([]);
  const [mobile_input, setmobile_input] = useState("");

  const handleClose = () => setOpen(false);
  const name = useInput("");
  const city = useInput("");
  const vehicle_type = useInput("");
  const vehicle_model = useInput("");
  const year = useInput("");
  const loanRequired = useInput("");
  const handelSubmit = (e) => {
    e.preventDefault();
    let data_to_post = {
      LeadName: name.value,
      LeadSourceId: 7,
      MobileNumber: mobile_input,
      CityId: city.value,
      VehicleModelId: vehicle_model.value,
      MfgYr: value.get("year"),
      loanRequired: loanRequired.value,
    };
    customFetch(book_now_url, { body: data_to_post, method: "POST" }).then(
      (data) => {
        gtag_report_conversion(window.location.href);
        setOpen(false);
        showToastMessage(
          data.SUCCESS,
          "Thank you for your interest ! We will get back to you"
        );
      }
    );
    name.onChange({ target: { value: "" } });
    setmobile_input("");
    city.onChange({ target: { value: "" } });
    vehicle_type.onChange({ target: { value: "" } });
    vehicle_model.onChange({ target: { value: "" } });
    setValue(null);
  };
  useEffect(() => {
    customFetch(city_url, {
      method: "GET",
    }).then((data) => setCity_drop_menu(data.DATA));
    customFetch(vehicle_brand_url, {
      method: "GET",
    }).then((data) => setVehicle_brand_menu([...data.DATA]));
  }, []);
  useEffect(() => {
    let url = `${base_url}/vehicle-brand/v1/${vehicle_type.value}/model`;
    customFetch(url, { method: "GET" }).then((data) =>
      setVehicle_model_menu([...data.DATA])
    );
  }, [vehicle_type.value]);
  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    // eslint-disable-next-line no-undef
    gtag("event", "conversion", {
      send_to: "AW-11120149118/p5gbCNrqrJIYEP6EwLYp",
      event_callback: callback,
    });
    return false;
  }
  const handleMobileInput = (event) => {
    const regex = /^[0-9\b]+$/; // Only allow numbers (0-9) and backspace (\b)
    if (
      (event.target.value === "" || regex.test(event.target.value)) &&
      event.target.value.length <= 10
    ) {
      setmobile_input(event.target.value);
    }
  };
  return (
    <div>
      <ToastContainer />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="box-modal">
          
          <div className="d-flex justify-content-between">
            <div className="">
              <h3>+91 9945387671</h3>
              {/* <span>Experience i-Hybrid</span> */}
            </div>
            <div className="close-button">
              <img
                src="https://cdn-icons-png.flaticon.com/512/2961/2961937.png"
                alt="error"
                width={"25px"}
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}


// // insert in after line 150 
//    <form className="d-flex flex-column gap-4" onSubmit={handelSubmit}>
//              <TextField
//               id="outlined-basic"
//               label="Enter Your Name"
//               variant="outlined"
//               {...name}
//               required
//             />
//             <TextField
//               id="outlined-basic"
//               label="Phone Number"
//               variant="outlined"
//               value={mobile_input}
//               onChange={handleMobileInput}
//               required
//             />

//             <div className="d-flex flex-column gap-2">
//               <FormControl fullWidth required>
//                 <InputLabel id="demo-simple-select-label">Location</InputLabel>
//                 <Select
//                   labelId="demo-simple-select-label"
//                   id="demo-simple-select"
//                   label="Location"
//                   {...city}
//                 >
//                   {city_drop_menu.map((item) => {
//                     return (
//                       <MenuItem value={item.Id} key={item.Id}>
//                         {" "}
//                         {item.City}
//                       </MenuItem>
//                     );
//                   })}
//                 </Select>
//               </FormControl>
//               <label className="fs-6 text-warning" htmlFor="demo-simple-select">
//                 We are currently available in Bengaluru only.
//               </label>
//             </div>

//             <FormControl fullWidth required>
//               <InputLabel id="demo-simple-select-label">
//                 Vehicle Type
//               </InputLabel>
//               <Select
//                 labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 label=" Vehicle Type"
//                 {...vehicle_type}
//               >
//                 {vehicle_brand_menu.map((item) => (
//                   <MenuItem value={item.BrandId} key={item.BrandId}>
//                     {item.Brand}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>

//             <FormControl fullWidth>
//               <InputLabel id="demo-simple-select-label">Model</InputLabel>
//               <Select
//                 labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 label="Model"
//                 {...vehicle_model}
//               >
//                 {vehicle_model_menu.map((item) => (
//                   <MenuItem value={item.ModelId} key={item.ModelId}>
//                     {item.Model}-{item.Variant}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <div className="d-flex flex-column gap-2">
//               <FormControl fullWidth>
//                 <InputLabel id="demo-simple-select-label">Year</InputLabel>
//                 <Select
//                   labelId="demo-simple-select-label"
//                   id="demo-simple-select"
//                   label="Year"
//                   {...year}
//                 >
//                   <MenuItem value={"Before 2012"}>Before 2012</MenuItem>
//                   <MenuItem value={"2012 or after"}>2012 or after</MenuItem>
//                 </Select>
//               </FormControl>
//               <label className="fs-6 text-warning" htmlFor="demo-simple-select">
//                 We are certified to convert vehicles which were made in and
//                 after 2012
//               </label>
//             </div>

//             <FormControl fullWidth>
//               <InputLabel id="demo-simple-select-label">
//                 Loan Required?
//               </InputLabel>
//               <Select
//                 labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 label="Loan Required"
//                 {...loanRequired}
//               >
//                 <MenuItem value={"0"} key={0}>
//                   No
//                 </MenuItem>
//                 <MenuItem value={"1"} key={1}>
//                   Yes
//                 </MenuItem>
//               </Select>
//             </FormControl>

//              <LocalizationProvider dateAdapter={AdapterDayjs}>
//               <Stack spacing={3}>
//                 <DatePicker
//                   views={["year"]}
//                   label="Year"
//                   minDate={"2010"}
//                   value={value}
//                   onChange={(newValue) => {
//                     setValue(newValue);
//                   }}
//                   renderInput={(params) => (
//                     <TextField {...params} helperText={null} />
//                   )}
//                 />
//               </Stack>
//             </LocalizationProvider> 
//             <button type="submit" className="btn btn-success gt-bg">
//               Submit
//                   </button> 
//                 </form> 
