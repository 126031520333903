import React from "react";
import "./Styles/VideowithTxtanim.css";
import { TypeAnimation } from "react-type-animation";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fab } from "@mui/material";
import CalculateIcon from "@mui/icons-material/Calculate";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import CalculatorModal from "./calculator_modal/CalculatorModal";
import EmiCalculator from "./Emi_modal/EmiCalculator";
const styles = {
  width: "250px",
  background: "white",
  margin: "auto",
  fontSize: "14px",
};

export default function VideowithTxtanim({ videolink, title, animationSeq, justifyContentBetween = false, url, bg, gtalign, typingwithLogo, setOpen_video }) {
  let c = "align-items-center justify-content-between";
  if (justifyContentBetween) c = "justify-content-around";
  let [imgNumber, setimageNumber] = useState(0);
  const [landing_anim, setlanding_anim] = useState(false);
  const [open, setOpen] = useState(false);
  const [open_emi, setOpen_emi] = useState(false);
  let [logoNumber, setlogoNumber] = useState(0);

  useEffect(() => {
    setInterval(() => {
      if (imgNumber < 3) {
        imgNumber += 1;
        setimageNumber(imgNumber);
      } else {
        imgNumber = 0;
        setimageNumber(imgNumber);
      }
    }, 3430);
    setInterval(() => {
      if (logoNumber < 4) {
        logoNumber += 1;
        setlogoNumber(logoNumber);
      } else {
        logoNumber = 0;
        setlogoNumber(logoNumber);
      }
    }, 3430);
    if (window.innerWidth <= 576) {
      setlanding_anim(true);
    }
  }, []);
  const navigate = useNavigate();
  return (
    <div className={`new-page-intro-container d-flex flex-column py-2 ${typingwithLogo && "position-relative vehicle-convo"}    ${c}`} style={{ backgroundImage: `url(${bg})` }}>
      <div className={`intro-section ${typingwithLogo && "vehicle-convert-intro"}  container-fluid`}>
        <div className={`intro-header  ${typingwithLogo && "vehicle-header"}    d-flex flex-column flex-lg-row justify-content-around  justify-content-lg-between  align-items-center`}>
          <div className={`intro-left-part d-flex flex-column   ${typingwithLogo && "gap-5"} `}>
            <h1 className="intro-para-first-word fw-bold gt-color text-center ">{title}</h1>

            <div className={`type-anime  ${!typingwithLogo ? "first-sec-type" : "vehicle-conv-logo"}`}>
              {typingwithLogo && (
                <>
                  {imgNumber === 0 && <img src={url[0]} className="imag-animation" alt="error" width={"80px"} />}
                  {imgNumber === 1 && <img src={url[1]} className="imag-animation" alt="error" width={"80px"} />}
                  {imgNumber === 2 && <img src={url[2]} className="imag-animation" alt="error" width={"80px"} />}
                  {imgNumber === 3 && <img src={url[3]} className="imag-animation" alt="error" width={"80px"} />}
                </>
              )}
              <div className={`vehicle-conv-type ${typingwithLogo && "vehicle-we-conv"} mt-0 mt-lg-5`}>
                <TypeAnimation
                  sequence={animationSeq}
                  wrapper="p"
                  cursor={false}
                  repeat={Infinity}
                  style={{
                    fontSize: "42px",
                    color: "black",
                    textAlign: ` ${typingwithLogo ? "justify" : "center"} `,
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`intro-right-part ${gtalign && "special-class"} ${typingwithLogo && "vehicle-right-part"} `}>
            <video src={videolink} muted autoPlay></video>
          </div>
        </div>
      </div>
      {!typingwithLogo ? (
        <div className={`footer intro-footer container-fluid d-flex align-items-center justify-content-evenly   ${landing_anim && "position-relative"}  p-0`}>
          <div className={`intro-foot-box-1 intro-foot-items d-flex flex-column align-items-center justify-content-evenly `}>
            <img src={url[0]} alt="" className={` ${!gtalign ? "special-icon" : ""} ${landing_anim && logoNumber === 0 && "landing-active-logo"} `} />
          </div>
          <div className={`intro-foot-box-2 intro-foot-items d-flex flex-column align-items-center justify-content-evenly gap-2  `}>
            <img src={url[1]} alt="" className={`${landing_anim && logoNumber === 1 && "landing-active-logo"}`} />
          </div>
          <div className={`intro-foot-box-3  intro-foot-items d-flex flex-column align-items-center justify-content-evenly `}>
            <img src={url[2]} alt="error" className={`${(landing_anim && logoNumber) === 2 && "landing-active-logo"} ${!gtalign ? "made-in-india" : ""}  `} />
          </div>
          <div className={`intro-foot-box-4 intro-foot-items d-flex flex-column align-items-center justify-content-evenly`}>
            <img src={url[3]} alt="" className={` ${(landing_anim && logoNumber) === 3 && "landing-active-logo"}`} />
          </div>
          {url[4] && (
            <div className={`intro-foot-box-5 intro-foot-items  d-flex flex-column align-items-center justify-content-evenly`}>
              <img src={url[4]} alt="" className={` ${(landing_anim && logoNumber) === 4 && "landing-active-logo"}`} />
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex  w-100 justify-content-sm-center justify-content-start  flex-wrap vehicle-btn-container">
          <CalculatorModal open={open} setOpen={setOpen} />
          <EmiCalculator open={open_emi} setOpen={setOpen_emi} />
          <div className="d-none d-flex justify-content-between btn-contain flex-wrap flex-sm-nowrap gap-3 gap-sm-0  ">
            <Fab
              variant="extended"
              sx={{
                width: "250px",
                background: "white",
                margin: "auto",
                fontSize: "14px",
                "@media(max-width: 576px)": {
                  width: "200px",
                  height: "35px",
                  fontSize: "10px",
                },
              }}
              onClick={() => setOpen_emi(true)}
            >
              <CalculateIcon sx={{ mr: 1 }} />
              EMI Calculator
            </Fab>
          </div>
          <div className="d-flex justify-content-between btn-contain flex-wrap flex-sm-nowrap gap-3 gap-sm-0">
            <Fab
              variant="extended"
              sx={{
                width: "250px",
                background: "white",
                margin: "auto",
                fontSize: "14px",
                "@media(max-width: 576px)": {
                  width: "200px",
                  height: "35px",
                  fontSize: "10px",
                },
              }}
              onClick={() => setOpen(true)}
            >
              <CalculateIcon sx={{ mr: 1 }} />
              Saving Calcultor
            </Fab>
            <Fab
              variant="extended"
              sx={{
                width: "250px",
                background: "white",
                margin: "auto",
                fontSize: "14px",
                "@media(max-width: 576px)": {
                  width: "200px",
                  height: "35px",
                  fontSize: "10px",
                },
              }}
              onClick={() => setOpen_video(true)}
            >
              <TouchAppIcon sx={{ mr: 1 }} />
              Call Now
            </Fab>
            <Fab
              variant="extended"
              sx={{
                width: "250px",
                background: "white",
                margin: "auto",
                fontSize: "14px",
                "@media(max-width: 576px)": {
                  width: "200px",
                  height: "35px",
                  fontSize: "10px",
                },
              }}
              onClick={() => navigate("/FAQ")}
            >
              <LiveHelpIcon sx={{ mr: 1 }} />
              FAQs
            </Fab>
          </div>
        </div>
      )}
    </div>
  );
}
