import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Styles/Nav.css";
import BasicModal from "./Modal/BasicModal";
import TemporaryDrawer from "./RightDrawer/TemporaryDrawer";
import { Fab } from "@mui/material";

export default function Nav({ open, setOpen }) {
  const [opendrawer, setopendrawer] = useState(false);
  const handleOpen = () => setOpen(true);

  const headStripText = ["EMI Options Available", "2 Years of Warranty*"];
  let [activeStripIndex, setActiveStripIndex] = useState(0);

  useEffect(() => {
    let x = setInterval(() => {
      if (activeStripIndex < headStripText.length)
        setActiveStripIndex(activeStripIndex++);
      else {
        activeStripIndex = 0;
      }
    }, 2000);
  }, []);

  return (
    <div className="nav-container">
      <div className="nav-footer d-flex justify-content-center">
        {headStripText.map((t, i) => (
          <p
            key={i}
            className={`m-0 ${
              activeStripIndex == i
                ? "visible opacity-100"
                : "invisible opacity-0"
            }`}
          >
            {t}
          </p>
        ))}
      </div>
      <nav
        className="d-flex justify-content-between align-items-center bg-light "
        id="nav"
      >
        {/* logo-box */}
        <div>
          <Link to={"/"}>
            <div className="logo"></div>
          </Link>
        </div>
        {/* right-part */}
        <div className="d-flex justify-content-between nav-right-side align-items-center  ">
          <Fab
            type="button"
            variant="extended"
            href="https://goo.gl/maps/aBQ7ULttysAdo8HV7"
            target={"_blank"}
            sx={{
              background: "white",
            }}
            className="nav-our-product-btn "
          >
            Store Locator
          </Fab>

          <Fab
            href="#"
            variant="extended"
            sx={{
              background: "white",
            }}
            onClick={handleOpen}
          >
            Call Now
          </Fab>

          <i
            className="fa-solid fa-bars hamburger"
            onClick={() => setopendrawer(!opendrawer)}
          ></i>
        </div>
      </nav>
      <BasicModal open={open} setOpen={setOpen} />
      <TemporaryDrawer opendrawer={opendrawer} setopendrawer={setopendrawer} />
    </div>
  );
}
